import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../../components/layout/layout'
import Img from 'gatsby-image'
import SEO from '../../components/seo/seo'
import { BigPlayButton, ControlBar, Player } from 'video-react'
import Div100vh from 'react-div-100vh'

class Steps extends React.Component {
  render () {
    let gridMarkup;
    if (this.props.data.allFile !== null) {
      gridMarkup = (
        this.props.data.allFile.edges.map((img, idx) => {
          let linkIndex = this.props.data.dataJson.links.findIndex(
            link => link.number == (idx + 1))
          let videoIndex = this.props.data.dataJson.videos.findIndex(
            video => video.number == (idx + 1))
          if (linkIndex > -1) {
            return (
              <div className="c-grid__item" key={ img.node.id }>
                <a
                  href={ this.props.data.dataJson.links[linkIndex].url }
                >
                  <Img fluid={ img.node.childImageSharp.fluid }
                    className="c-grid__media"/>
                </a>
              </div>
            )
          } else if (videoIndex > -1) {
            return (
              <div className="c-grid__item" key={ img.node.id }>
                <div className="c-grid__media">
                  <Player poster={ img.node.childImageSharp.fluid.src }>
                    <source src={ withPrefix('/videos/' +
                      img.node.name + '.mp4') }/>
                    <ControlBar disableCompletely={ true }/>
                    <BigPlayButton position="center"/>
                  </Player>
                </div>
                {/* eslint-disable */ }
                <svg viewBox="0 0 640 640" xmlns="http://www.w3.org/2000/svg"
                     className="c-grid__video-icon">
                  <path
                    d="m512 224l128-128v448l-128-128v96c0 35.346-28.654 64-64 64h-384c-35.346 0-64-28.654-64-64v-384c0-35.2 28.8-64 64-64h384c35.346 0 64 28.654 64 64v96zm-256 224c70.693 0 128-57.308 128-128s-57.308-128-128-128-128 57.308-128 128 57.308 128 128 128zm0-64c-35.346 0-64-28.654-64-64s28.654-64 64-64 64 28.654 64 64-28.654 64-64 64z"/>
                </svg>
                {/* eslint-enable */ }
              </div>
            )
          }
          return (
            <div className="c-grid__item" key={ img.node.id }>
              <Img fluid={ img.node.childImageSharp.fluid }
                className="c-grid__media"/>
            </div>
          )
        })
      )
    }
    return (
      <Layout>
        <Helmet>
          <body className="is-grid" />
        </Helmet>
        <SEO title={this.props.data.dataJson.page} />
        <Div100vh style={{ minHeight: '100rvh' }}>
          <div className="c-grid">
            <div className="container">
              <div className="row">
                <div className="c-grid__container">
                  <div className="c-grid__items">
                    {gridMarkup}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Div100vh>
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    dataJson(page: { eq: $slug }) {
      page,
      links {
        number,
        url
      },
      videos {
        number
      }
    }
    allFile (
      sort: {order: ASC, fields: [name]},
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
      relativeDirectory: {eq: $slug}}
    )
      {
        edges {
          node {
              childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid (maxWidth: 400) {
                ...GatsbyImageSharpFluid,
                src
              }
            },
            name,
            id
        }
      }
    }
  }
`

export default Steps
